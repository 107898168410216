@use '../../variables' as v;

@media only screen and (min-width:v.$bp-xl){
    .company{
        height:100%;
        padding-top:10vh;
        padding-bottom:10vh;
        background:v.$white;

        img{
            width:95%;
            border-radius:250px 10px 250px 10px;
            box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;

        }

        h2{
            color:v.$orange;
        }

        button{
            width:25%;
            color:white;
            font-size:1.2rem;
            border-style:none;
            padding:5px;
            border-radius:10px;
            display:inline-block;
            transition: 0.5s;
            background:v.$green;
            margin-top:2vh;
            box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
        }
    }
}
@media only screen and (max-width:v.$bp-xl){
    .company{
        height:100%;
        padding-top:5vh;
        padding-bottom:10vh;
        background:v.$white;

        img{
            width:100%;
            border-radius:150px 10px 150px 10px;
            margin-top:10vh;
            height: 25vh;
        }

        h2{
            color:v.$orange;
            width:95%;
            margin-top:3vh;
            margin-left:2.5%;
        }

        h4{
            width:95%;
            margin-left:2.5%;

        }

        button{
            margin-left:2.5%;
            width:50%;
            color:white;
            font-size:1.5rem;
            border-style:none;
            padding:5px;
            border-radius:10px;
            display:inline-block;
            transition: 0.5s;
            background:v.$green;
            margin-top:1vh;
        }
    }
}
@media only screen and (max-width:v.$bp-md){
    .company{
        height:100%;
        padding-top:5vh;
        padding-bottom:10vh;
        background:v.$white;

        img{
            width:100%;
            border-radius:150px 10px 150px 10px;
        }

        h2{
            color:v.$orange;
            width:95%;
            margin-top:3vh;
            margin-bottom:3vh;
            margin-left:2.5%;
        }

        h4{
            width:95%;
            margin-left:2.5%;

        }

        button{
            margin-left:2.5%;
            width:50%;
            color:white;
            font-size:1.5rem;
            border-style:none;
            padding:5px;
            border-radius:10px;
            display:inline-block;
            transition: 0.5s;
            background:v.$green;
            margin-top:3vh;
        }
    }
}