@use '../../variables' as v;



.privacyPolicy, .termsOfService{
    body{
        height:100%;
        margin:0;
        padding:0;
    }
    
    h1{
        margin-top:10vh;
        margin-bottom:5vh;
    }
    
    p{
        font-size:1.3rem;
        margin-bottom:5vh;
    }
}

