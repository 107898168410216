$bp-xs:0px;
$bp-small:576px;
$bp-md:768px;
$bp-large:992px;
$bp-xl: 1200px;

//colors
$blue: #03a9f4;
$orange:#f6b32a;
$white: #F5F5FA;
$green: #07a59d;


.green-btn{
    background-color:$green;
}

.orange-btn{
    background-color:$orange;
}

.blue-btn{
    background-color:$blue;
}


//consistent font sizes and weights
h1{
    font-size:2.5rem;
    font-weight:700;
}
h2{
    font-size:1.8rem;
    font-weight:600;
}
h3{
    font-size:1.6rem;
    font-weight:500;
}
h4{
    font-size:1.4rem;
    font-weight:400;
}

.green{
    color:$green;
}

.orange{
    color:$orange;
}
