@use '../../variables' as v;

@media only screen and (min-width:v.$bp-xl){
    .homepage{
        background-image:url('../images/mainBackground.png');
        background-size:cover;
        height:100%;
        width:100%;
        padding-top:13vh;
        padding-bottom:15vh;
        box-shadow:inset 0 0 0 1000px rgba(0,0,0,0.38);

        h3{
            color:black;
        }

        h4{
            color:white;
            margin-top:2vh;
        }

        button{
            width:25%;
            color:white;
            font-size:1.2rem;
            border-style:none;
            padding:5px;
            border-radius:10px;
            margin-top:2vh;
            display:inline-block;
            transition: 0.5s;

            &:first-of-type{
                background:v.$green;
                margin-right:.5vw;
            }

            &:last-of-type{
                background:v.$orange;
            }

            &:hover{
                opacity:75%;
            }
        }

        form{
            width:100%;
            background-color: v.$white;
            padding:5%;
            text-align: center;
            align-items: center;
            border-radius: 15px;
            border:none;
            
            input{
                width:100%;
                padding:15px;
                border:none;
                border-bottom:1px solid black;
                 
                &:focus{
                    outline:none;
                }
            }

            button{
                width:45%;

                &:first-of-type{
                    background:v.$blue;
                }
            }
        }
    }
}

@media only screen and (max-width:v.$bp-xl){
    .homepage{
        background-image: url('../images/mainBackground.png');
        background-size:cover;
        height:100%;
        width:100%;
        padding-top:11vh;
        padding-bottom:5vh;
        box-shadow:inset 0 0 0 1000px rgba(0,0,0,0.68);

        h3{
            color:black;
        }

        h4{
            color:white;
            margin-top:2vh;
        }

        button{
            width:49%;
            color:white;
            font-size:1.5rem;
            border-style:none;
            padding:5px;
            border-radius:10px;
            margin-top:2vh;
            margin-bottom:4vh;
            display:inline-block;
            transition: 0.5s;

            &:hover{
                opacity:75%;
            }
        }

        form{
            width:100%;
            background-color: v.$white;
            padding:5%;
            padding-bottom:0;
            text-align: center;
            align-items: center;
            border-radius: 15px;
            border:none;
            
            input{
                width:100%;
                padding:5px;
                border:none;
                border-bottom:1px solid black;
                background-color:v.$white;
                margin-top:0.5vh;
                font-size:1.3rem;
                margin-top:1.5vh;

                &:focus{
                    outline:none;
                }
            }

            button{
                width:49%;

            }
        }
    }
}

@media only screen and (max-width:v.$bp-md){
    .homepage{
        background-image: url('../images/mainBackground.png');
        background-size:cover;
        height:100%;
        width:100%;
        padding-top:11vh;
        padding-bottom:5vh;
        box-shadow:inset 0 0 0 1000px rgba(0,0,0,0.68);

        h3{
            color:black;
        }

        h4{
            color:white;
            margin-top:2vh;
        }

        button{
            width:49%;
            color:white;
            font-size:1.5rem;
            border-style:none;
            padding:5px;
            border-radius:10px;
            margin-top:2vh;
            margin-bottom:4vh;
            display:inline-block;
            transition: 0.5s;

            &:hover{
                opacity:75%;
            }
        }

        form{
            width:100%;
            background-color: v.$white;
            padding:5%;
            padding-bottom:0;
            text-align: center;
            align-items: center;
            border-radius: 15px;
            border:none;
            
            input{
                width:100%;
                padding:5px;
                border:none;
                border-bottom:1px solid black;
                background-color:v.$white;
                margin-top:0.5vh;
                font-size:1.3rem;

                &:focus{
                    outline:none;
                }
            }

            button{
                width:49%;
            }
        }
    }
}

