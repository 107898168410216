@use '../../variables' as v;

@media only screen and (max-width:v.$bp-md) {
    .contact-page{
        padding-top:8vh;
        width:90%;
        margin-left:5%;
        padding-bottom:8vh;

        h3{
            font-size:2.5rem;
            color:v.$green;
        }

        input{
            margin-top:2vh;
            border:none;
            border-bottom: 1px solid black;
            font-size:1.3rem;
            border-radius:3px;
            padding:5px;
            font-weight:400;
            width:100%;

            &:focus{
                outline:none;
            }
        }

        button{
            width:50%;
            color:white;
            font-size:1.5rem;
            border-style:none;
            background-color:v.$blue;
            padding:5px;
            border-radius:10px;
            box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
            transition: 0.5s;
            margin-top:3vh;

            &:last-child{
                display:none;
            }
            &:focus{
                outline:none;
                border:none;
            }

        }

        
    }
}

@media only screen and (min-width:v.$bp-md) {
    .contact-page{
        padding-top:8vh;
        width:90%;
        margin-left:5%;
        padding-bottom:8vh;

        h3{
            font-size:2.5rem;
            color:v.$green;
        }

        input{
            margin-top:2vh;
            border:none;
            border-bottom: 1px solid black;
            font-size:1.3rem;
            border-radius:3px;
            padding:5px;
            font-weight:400;
            width:100%;

            &:focus{
                outline:none;
            }
        }

        button{
            width:50%;
            color:white;
            font-size:1.5rem;
            border-style:none;
            background-color:v.$blue;
            padding:5px;
            border-radius:10px;
            box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
            transition: 0.5s;
            margin-top:3vh;

            &:last-child{
                display:none;
            }
            &:focus{
                outline:none;
                border:none;
            }

        }

        
    }
}