.green-btn {
  background-color: #07a59d;
}

.orange-btn {
  background-color: #f6b32a;
}

.blue-btn {
  background-color: #03a9f4;
}

h1 {
  font-size: 2.5rem;
  font-weight: 700;
}

h2 {
  font-size: 1.8rem;
  font-weight: 600;
}

h3 {
  font-size: 1.6rem;
  font-weight: 500;
}

h4 {
  font-size: 1.4rem;
  font-weight: 400;
}

.green {
  color: #07a59d;
}

.orange {
  color: #f6b32a;
}

@media only screen and (min-width: 1200px) {
  .company {
    height: 100%;
    padding-top: 10vh;
    padding-bottom: 10vh;
    background: #F5F5FA;
  }
  .company img {
    width: 95%;
    border-radius: 250px 10px 250px 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  }
  .company h2 {
    color: #f6b32a;
  }
  .company button {
    width: 25%;
    color: white;
    font-size: 1.2rem;
    border-style: none;
    padding: 5px;
    border-radius: 10px;
    display: inline-block;
    transition: 0.5s;
    background: #07a59d;
    margin-top: 2vh;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  }
}
@media only screen and (max-width: 1200px) {
  .company {
    height: 100%;
    padding-top: 5vh;
    padding-bottom: 10vh;
    background: #F5F5FA;
  }
  .company img {
    width: 100%;
    border-radius: 150px 10px 150px 10px;
    margin-top: 10vh;
    height: 25vh;
  }
  .company h2 {
    color: #f6b32a;
    width: 95%;
    margin-top: 3vh;
    margin-left: 2.5%;
  }
  .company h4 {
    width: 95%;
    margin-left: 2.5%;
  }
  .company button {
    margin-left: 2.5%;
    width: 50%;
    color: white;
    font-size: 1.5rem;
    border-style: none;
    padding: 5px;
    border-radius: 10px;
    display: inline-block;
    transition: 0.5s;
    background: #07a59d;
    margin-top: 1vh;
  }
}
@media only screen and (max-width: 768px) {
  .company {
    height: 100%;
    padding-top: 5vh;
    padding-bottom: 10vh;
    background: #F5F5FA;
  }
  .company img {
    width: 100%;
    border-radius: 150px 10px 150px 10px;
  }
  .company h2 {
    color: #f6b32a;
    width: 95%;
    margin-top: 3vh;
    margin-bottom: 3vh;
    margin-left: 2.5%;
  }
  .company h4 {
    width: 95%;
    margin-left: 2.5%;
  }
  .company button {
    margin-left: 2.5%;
    width: 50%;
    color: white;
    font-size: 1.5rem;
    border-style: none;
    padding: 5px;
    border-radius: 10px;
    display: inline-block;
    transition: 0.5s;
    background: #07a59d;
    margin-top: 3vh;
  }
}/*# sourceMappingURL=company.css.map */