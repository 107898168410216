.green-btn {
  background-color: #07a59d;
}

.orange-btn {
  background-color: #f6b32a;
}

.blue-btn {
  background-color: #03a9f4;
}

h1 {
  font-size: 2.5rem;
  font-weight: 700;
}

h2 {
  font-size: 1.8rem;
  font-weight: 600;
}

h3 {
  font-size: 1.6rem;
  font-weight: 500;
}

h4 {
  font-size: 1.4rem;
  font-weight: 400;
}

.green {
  color: #07a59d;
}

.orange {
  color: #f6b32a;
}

@media only screen and (min-width: 992px) {
  .nav-link {
    font-size: 1.5rem;
  }
  .nav-main {
    width: 50px;
  }
  .nav-main img {
    width: 60px;
  }
}
@media only screen and (max-width: 992px) {
  .nav-link {
    font-size: 1.3rem;
  }
  .nav-main {
    width: 50px;
  }
  .nav-main img {
    width: 50px;
  }
}/*# sourceMappingURL=navbar.css.map */