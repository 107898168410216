.green-btn {
  background-color: #07a59d;
}

.orange-btn {
  background-color: #f6b32a;
}

.blue-btn {
  background-color: #03a9f4;
}

h1 {
  font-size: 2.5rem;
  font-weight: 700;
}

h2 {
  font-size: 1.8rem;
  font-weight: 600;
}

h3 {
  font-size: 1.6rem;
  font-weight: 500;
}

h4 {
  font-size: 1.4rem;
  font-weight: 400;
}

.green {
  color: #07a59d;
}

.orange {
  color: #f6b32a;
}

@media only screen and (min-width: 1200px) {
  .homepage {
    background-image: url("../images/mainBackground.png");
    background-size: cover;
    height: 100%;
    width: 100%;
    padding-top: 13vh;
    padding-bottom: 15vh;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.38);
  }
  .homepage h3 {
    color: black;
  }
  .homepage h4 {
    color: white;
    margin-top: 2vh;
  }
  .homepage button {
    width: 25%;
    color: white;
    font-size: 1.2rem;
    border-style: none;
    padding: 5px;
    border-radius: 10px;
    margin-top: 2vh;
    display: inline-block;
    transition: 0.5s;
  }
  .homepage button:first-of-type {
    background: #07a59d;
    margin-right: 0.5vw;
  }
  .homepage button:last-of-type {
    background: #f6b32a;
  }
  .homepage button:hover {
    opacity: 75%;
  }
  .homepage form {
    width: 100%;
    background-color: #F5F5FA;
    padding: 5%;
    text-align: center;
    align-items: center;
    border-radius: 15px;
    border: none;
  }
  .homepage form input {
    width: 100%;
    padding: 15px;
    border: none;
    border-bottom: 1px solid black;
  }
  .homepage form input:focus {
    outline: none;
  }
  .homepage form button {
    width: 45%;
  }
  .homepage form button:first-of-type {
    background: #03a9f4;
  }
}
@media only screen and (max-width: 1200px) {
  .homepage {
    background-image: url("../images/mainBackground.png");
    background-size: cover;
    height: 100%;
    width: 100%;
    padding-top: 11vh;
    padding-bottom: 5vh;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.68);
  }
  .homepage h3 {
    color: black;
  }
  .homepage h4 {
    color: white;
    margin-top: 2vh;
  }
  .homepage button {
    width: 49%;
    color: white;
    font-size: 1.5rem;
    border-style: none;
    padding: 5px;
    border-radius: 10px;
    margin-top: 2vh;
    margin-bottom: 4vh;
    display: inline-block;
    transition: 0.5s;
  }
  .homepage button:hover {
    opacity: 75%;
  }
  .homepage form {
    width: 100%;
    background-color: #F5F5FA;
    padding: 5%;
    padding-bottom: 0;
    text-align: center;
    align-items: center;
    border-radius: 15px;
    border: none;
  }
  .homepage form input {
    width: 100%;
    padding: 5px;
    border: none;
    border-bottom: 1px solid black;
    background-color: #F5F5FA;
    margin-top: 0.5vh;
    font-size: 1.3rem;
    margin-top: 1.5vh;
  }
  .homepage form input:focus {
    outline: none;
  }
  .homepage form button {
    width: 49%;
  }
}
@media only screen and (max-width: 768px) {
  .homepage {
    background-image: url("../images/mainBackground.png");
    background-size: cover;
    height: 100%;
    width: 100%;
    padding-top: 11vh;
    padding-bottom: 5vh;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.68);
  }
  .homepage h3 {
    color: black;
  }
  .homepage h4 {
    color: white;
    margin-top: 2vh;
  }
  .homepage button {
    width: 49%;
    color: white;
    font-size: 1.5rem;
    border-style: none;
    padding: 5px;
    border-radius: 10px;
    margin-top: 2vh;
    margin-bottom: 4vh;
    display: inline-block;
    transition: 0.5s;
  }
  .homepage button:hover {
    opacity: 75%;
  }
  .homepage form {
    width: 100%;
    background-color: #F5F5FA;
    padding: 5%;
    padding-bottom: 0;
    text-align: center;
    align-items: center;
    border-radius: 15px;
    border: none;
  }
  .homepage form input {
    width: 100%;
    padding: 5px;
    border: none;
    border-bottom: 1px solid black;
    background-color: #F5F5FA;
    margin-top: 0.5vh;
    font-size: 1.3rem;
  }
  .homepage form input:focus {
    outline: none;
  }
  .homepage form button {
    width: 49%;
  }
}/*# sourceMappingURL=homepage.css.map */