@use '../../variables' as v;

.footer{
    background-color:v.$orange;
    color:white;
    margin-top:0px;
    position: relative;
    bottom:0;
    min-width:100vw;
    position: relative;
}

.social-icon{
    margin-right:15px;
}

.footer-links a {
    color: #ffffff;
    text-decoration: none;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }


