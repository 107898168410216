.green-btn {
  background-color: #07a59d;
}

.orange-btn {
  background-color: #f6b32a;
}

.blue-btn {
  background-color: #03a9f4;
}

h1 {
  font-size: 2.5rem;
  font-weight: 700;
}

h2 {
  font-size: 1.8rem;
  font-weight: 600;
}

h3 {
  font-size: 1.6rem;
  font-weight: 500;
}

h4 {
  font-size: 1.4rem;
  font-weight: 400;
}

.green {
  color: #07a59d;
}

.orange {
  color: #f6b32a;
}

.footer {
  background-color: #f6b32a;
  color: white;
  margin-top: 0px;
  position: relative;
  bottom: 0;
  min-width: 100vw;
  position: relative;
}

.social-icon {
  margin-right: 15px;
}

.footer-links a {
  color: #ffffff;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}/*# sourceMappingURL=footer.css.map */