.green-btn {
  background-color: #07a59d;
}

.orange-btn {
  background-color: #f6b32a;
}

.blue-btn {
  background-color: #03a9f4;
}

h1 {
  font-size: 2.5rem;
  font-weight: 700;
}

h2 {
  font-size: 1.8rem;
  font-weight: 600;
}

h3 {
  font-size: 1.6rem;
  font-weight: 500;
}

h4 {
  font-size: 1.4rem;
  font-weight: 400;
}

.green {
  color: #07a59d;
}

.orange {
  color: #f6b32a;
}

@media only screen and (max-width: 768px) {
  .contact-page {
    padding-top: 8vh;
    width: 90%;
    margin-left: 5%;
    padding-bottom: 8vh;
  }
  .contact-page h3 {
    font-size: 2.5rem;
    color: #07a59d;
  }
  .contact-page input {
    margin-top: 2vh;
    border: none;
    border-bottom: 1px solid black;
    font-size: 1.3rem;
    border-radius: 3px;
    padding: 5px;
    font-weight: 400;
    width: 100%;
  }
  .contact-page input:focus {
    outline: none;
  }
  .contact-page button {
    width: 50%;
    color: white;
    font-size: 1.5rem;
    border-style: none;
    background-color: #03a9f4;
    padding: 5px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    transition: 0.5s;
    margin-top: 3vh;
  }
  .contact-page button:last-child {
    display: none;
  }
  .contact-page button:focus {
    outline: none;
    border: none;
  }
}
@media only screen and (min-width: 768px) {
  .contact-page {
    padding-top: 8vh;
    width: 90%;
    margin-left: 5%;
    padding-bottom: 8vh;
  }
  .contact-page h3 {
    font-size: 2.5rem;
    color: #07a59d;
  }
  .contact-page input {
    margin-top: 2vh;
    border: none;
    border-bottom: 1px solid black;
    font-size: 1.3rem;
    border-radius: 3px;
    padding: 5px;
    font-weight: 400;
    width: 100%;
  }
  .contact-page input:focus {
    outline: none;
  }
  .contact-page button {
    width: 50%;
    color: white;
    font-size: 1.5rem;
    border-style: none;
    background-color: #03a9f4;
    padding: 5px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    transition: 0.5s;
    margin-top: 3vh;
  }
  .contact-page button:last-child {
    display: none;
  }
  .contact-page button:focus {
    outline: none;
    border: none;
  }
}/*# sourceMappingURL=contact.css.map */