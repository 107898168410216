@use '../../variables' as v;

@media only screen and (min-width:v.$bp-xl){
    .packages{
        background-color:v.$white;
        padding-top:8vh;
        padding-bottom:8vh;

        .container-package{
            width:100%;
            margin-top:3vh;
            padding:2vh;
            background-color:v.$blue;
            text-align: center;
            color:v.$white;
            box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
            border-radius:10px;

            .top-section{
                border-bottom-style:solid;
                border-bottom-width:2px;
                padding-bottom:1.5vh;
            }

            .middle-section{
                padding-top:1.5vh;
                padding-bottom:1.5vh;
                border-bottom-style:solid;
                border-bottom-width:2px;
            }

            .bottom-section{
                padding-top:1.5vh;
                padding-bottom:1.5vh;
                text-align: left;
            }

            .orange-btn{
                width:50%;
                color:white;
                font-size:1.5rem;
                border-style:none;
                background-color:v.$orange;
                padding:5px;
                border-radius:10px;
                transition: 0.5s;
                box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
            }
        }

        
    }
}
@media only screen and (min-width:v.$bp-md){
    .packages{
        background-color:v.$white;
        padding-top:8vh;
        padding-bottom:8vh;

        .container-package{
            width:100%;
            margin-top:3vh;
            padding:2vh;
            background-color:v.$blue;
            text-align: center;
            color:v.$white;
            box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
            border-radius:10px;

            .top-section{
                border-bottom-style:solid;
                border-bottom-width:2px;
                padding-bottom:1.5vh;
            }

            .middle-section{
                padding-top:1.5vh;
                padding-bottom:1.5vh;
                border-bottom-style:solid;
                border-bottom-width:2px;
            }

            .bottom-section{
                padding-top:1.5vh;
                padding-bottom:1.5vh;
                text-align: left;
            }

            .orange-btn{
                width:50%;
                color:white;
                font-size:1.5rem;
                border-style:none;
                background-color:v.$orange;
                padding:5px;
                border-radius:10px;
                transition: 0.5s;
                box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
            }
        }

        
    }
}
@media only screen and (max-width:v.$bp-md){
    .packages{
        background-color:v.$white;
        padding-top:8vh;
        padding-bottom:8vh;

        .title{
            margin-left:2.5%;
        }
        .container-package{
            width:100%;
            margin-top:3vh;
            padding:2vh;
            background-color:v.$blue;
            text-align: center;
            color:v.$white;
            width:95%;
            margin-left:2.5%;
            box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
            border-radius:10px;
            .top-section{
                border-bottom-style:solid;
                border-bottom-width:2px;
                padding-bottom:1.5vh;
            }

            .middle-section{
                padding-top:1.5vh;
                padding-bottom:1.5vh;
                border-bottom-style:solid;
                border-bottom-width:2px;
            }

            .bottom-section{
                padding-top:1.5vh;
                padding-bottom:1.5vh;
                text-align: left;
            }

            .orange-btn{
                width:50%;
                color:white;
                font-size:1.5rem;
                border-style:none;
                background-color:v.$orange;
                padding:5px;
                border-radius:10px;
                box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
                transition: 0.5s;
            }
        }

        
    }
}


.strikethrough{
    text-decoration: line-through;
    font-weight:200;
}