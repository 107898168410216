.green-btn {
  background-color: #07a59d;
}

.orange-btn {
  background-color: #f6b32a;
}

.blue-btn {
  background-color: #03a9f4;
}

h1 {
  font-size: 2.5rem;
  font-weight: 700;
}

h2 {
  font-size: 1.8rem;
  font-weight: 600;
}

h3 {
  font-size: 1.6rem;
  font-weight: 500;
}

h4 {
  font-size: 1.4rem;
  font-weight: 400;
}

.green {
  color: #07a59d;
}

.orange {
  color: #f6b32a;
}

.privacyPolicy body, .termsOfService body {
  height: 100%;
  margin: 0;
  padding: 0;
}
.privacyPolicy h1, .termsOfService h1 {
  margin-top: 10vh;
  margin-bottom: 5vh;
}
.privacyPolicy p, .termsOfService p {
  font-size: 1.3rem;
  margin-bottom: 5vh;
}/*# sourceMappingURL=privacypolicy.css.map */