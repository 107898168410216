.green-btn {
  background-color: #07a59d;
}

.orange-btn {
  background-color: #f6b32a;
}

.blue-btn {
  background-color: #03a9f4;
}

h1 {
  font-size: 2.5rem;
  font-weight: 700;
}

h2 {
  font-size: 1.8rem;
  font-weight: 600;
}

h3 {
  font-size: 1.6rem;
  font-weight: 500;
}

h4 {
  font-size: 1.4rem;
  font-weight: 400;
}

.green {
  color: #07a59d;
}

.orange {
  color: #f6b32a;
}

@media only screen and (min-width: 1200px) {
  .packages {
    background-color: #F5F5FA;
    padding-top: 8vh;
    padding-bottom: 8vh;
  }
  .packages .container-package {
    width: 100%;
    margin-top: 3vh;
    padding: 2vh;
    background-color: #03a9f4;
    text-align: center;
    color: #F5F5FA;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    border-radius: 10px;
  }
  .packages .container-package .top-section {
    border-bottom-style: solid;
    border-bottom-width: 2px;
    padding-bottom: 1.5vh;
  }
  .packages .container-package .middle-section {
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    border-bottom-style: solid;
    border-bottom-width: 2px;
  }
  .packages .container-package .bottom-section {
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    text-align: left;
  }
  .packages .container-package .orange-btn {
    width: 50%;
    color: white;
    font-size: 1.5rem;
    border-style: none;
    background-color: #f6b32a;
    padding: 5px;
    border-radius: 10px;
    transition: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  }
}
@media only screen and (min-width: 768px) {
  .packages {
    background-color: #F5F5FA;
    padding-top: 8vh;
    padding-bottom: 8vh;
  }
  .packages .container-package {
    width: 100%;
    margin-top: 3vh;
    padding: 2vh;
    background-color: #03a9f4;
    text-align: center;
    color: #F5F5FA;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    border-radius: 10px;
  }
  .packages .container-package .top-section {
    border-bottom-style: solid;
    border-bottom-width: 2px;
    padding-bottom: 1.5vh;
  }
  .packages .container-package .middle-section {
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    border-bottom-style: solid;
    border-bottom-width: 2px;
  }
  .packages .container-package .bottom-section {
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    text-align: left;
  }
  .packages .container-package .orange-btn {
    width: 50%;
    color: white;
    font-size: 1.5rem;
    border-style: none;
    background-color: #f6b32a;
    padding: 5px;
    border-radius: 10px;
    transition: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  }
}
@media only screen and (max-width: 768px) {
  .packages {
    background-color: #F5F5FA;
    padding-top: 8vh;
    padding-bottom: 8vh;
  }
  .packages .title {
    margin-left: 2.5%;
  }
  .packages .container-package {
    width: 100%;
    margin-top: 3vh;
    padding: 2vh;
    background-color: #03a9f4;
    text-align: center;
    color: #F5F5FA;
    width: 95%;
    margin-left: 2.5%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    border-radius: 10px;
  }
  .packages .container-package .top-section {
    border-bottom-style: solid;
    border-bottom-width: 2px;
    padding-bottom: 1.5vh;
  }
  .packages .container-package .middle-section {
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    border-bottom-style: solid;
    border-bottom-width: 2px;
  }
  .packages .container-package .bottom-section {
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    text-align: left;
  }
  .packages .container-package .orange-btn {
    width: 50%;
    color: white;
    font-size: 1.5rem;
    border-style: none;
    background-color: #f6b32a;
    padding: 5px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    transition: 0.5s;
  }
}
.strikethrough {
  text-decoration: line-through;
  font-weight: 200;
}/*# sourceMappingURL=packages.css.map */