@use '../../variables' as v;


@media only screen and (min-width:v.$bp-xl){
    .services{
        background-color:v.$green;
        padding:8vh;
        .container-service{
        width:100%;
        margin-top:3vh;
        padding:0vh;
    
            h2{
            padding-bottom:1vh;
            }
    
            h4{
            padding-bottom:1vh;
            }
    
    
            .orange-btn{
                width:25%;
                color:white;
                font-size:1.2rem;
                border-style:none;
                background-color:v.$orange;
                padding:5px;
                border-radius:10px;
                transition: 0.5s;
                box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
            }
        }
    }
}
@media only screen and (min-width:v.$bp-md){
    .services{
        background-color:v.$green;
        padding:8vh;
        .container-service{
        width:100%;
        margin-top:3vh;
        padding:0vh;
    
            h2{
            padding-bottom:1vh;
            }
    
            h4{
            padding-bottom:1vh;
            }
    
    
            .orange-btn{
                width:25%;
                color:white;
                font-size:1.2rem;
                border-style:none;
                background-color:v.$orange;
                padding:5px;
                border-radius:10px;
                transition: 0.5s;
                box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
            }
        }
    }
}
@media only screen and (max-width:v.$bp-md){
    .services{
        background-color:v.$green;
        padding-top:8vh;
        padding-bottom:6vh;
        margin-bottom:0px;
        width:100%;
        
        .title{margin-left:2.5%;}
        .container-service{
        width:100%;
        margin-top:3vh;
        padding:0vh;
        width:95%;
        padding-left:2.5%;

            h2{
            padding-bottom:1vh;
            }
    
            h4{
            padding-bottom:1vh;
            }
    
            .orange-btn{
                width:50%;
                color:white;
                font-size:1.5rem;
                border-style:none;
                background-color:v.$orange;
                padding:5px;
                border-radius:10px;
                transition: 0.5s;
                box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
            }
        }
    }
}