@use '../../variables' as v;

@media only screen and (min-width:v.$bp-large){
    .nav-link{
        font-size:1.5rem;
    }
    
    .nav-main{
        width:50px;

        img{
            width:60px;
        }
    }
}

@media only screen and (max-width:v.$bp-large){
    .nav-link{
        font-size:1.3rem;
    }

    .nav-main{
        width:50px;
        
        img{
            width:50px;
        }
    }
}